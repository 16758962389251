import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from './ProtectedRoute';
import Transactions from 'views/user/Transactions';
import Profile from 'views/user/Profile';
import Support from 'views/other/Support';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const DataService = Loadable(lazy(() => import('views/utilities/Data')));
const AirtimeService = Loadable(lazy(() => import('views/utilities/Airtime')));
const ElectricityService = Loadable(lazy(() => import('views/utilities/Electricity')));
const CableService = Loadable(lazy(() => import('views/utilities/Cable')));
const ResultCheckService = Loadable(lazy(() => import('views/utilities/ResultCheck')));

const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <ProtectedRoute component={<MainLayout />} />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'user',
            children: [
                {
                    path: 'transactions',
                    element: <Transactions />
                }
            ]
        },
        {
            path: 'user',
            children: [
                {
                    path: 'profile',
                    element: <Profile />
                }
            ]
        },
        {
            path: 'more',
            children: [
                {
                    path: 'support',
                    element: <Support />
                }
            ]
        },
        {
            path: 'services',
            children: [
                {
                    path: 'data',
                    element: <DataService />
                }
            ]
        },
        {
            path: 'services',
            children: [
                {
                    path: 'airtime',
                    element: <AirtimeService />
                }
            ]
        },
        {
            path: 'services',
            children: [
                {
                    path: 'electricity',
                    element: <ElectricityService />
                }
            ]
        },
        {
            path: 'services',
            children: [
                {
                    path: 'cable',
                    element: <CableService />
                }
            ]
        },
        {
            path: 'services',
            children: [
                {
                    path: 'result-checker',
                    element: <ResultCheckService />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
