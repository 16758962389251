import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthReset = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthReset')));
const AuthResetOtp = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthResetOtp')));
const AuthResetConfirm = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthConfirmReset')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin3 />
        },
        {
            path: 'register',
            element: <AuthRegister3 />
        },
        {
            path: 'reset-password',
            element: <AuthReset />
        },
        {
            path: 'otp-reset-password',
            element: <AuthResetOtp />
        },
        {
            path: 'complete-reset-password',
            element: <AuthResetConfirm />
        }
    ]
};

export default AuthenticationRoutes;
